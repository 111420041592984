import * as React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { H4 } from './typography'

export const BookItem = ({ input }) => {
    return (
        <Wrapper to={`/books/${input.slug.current}`}>
            <GatsbyImage image={input.cover.asset.gatsbyImageData} alt={input.cover.alt} />
            <H4 margin="0" color="var(--color-primary-medium-shade)">{input.title}</H4>
        </Wrapper>
    )
}

const Wrapper = styled(Link)`
    margin: var(--space-06);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: var(--space-02);
`