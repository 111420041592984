import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Layout, PageTitle } from '../../components/layout'
import { BookItem } from '../../components/BookItem'

const BookPage = () => {
    const data = useStaticQuery(graphql`
        {
            allSanityBook {
                edges {
                    node {
                        _id
                        title
                        slug {
                            current
                        }
                        cover {
                            alt
                            asset {
                                gatsbyImageData(
                                    width: 600,
                                    aspectRatio: 1,
                                )
                            }
                        }
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Books`,
        description: `Books and education material written by Steve Treseler`
    }


    return (
        <Layout seo={seo}>
            <PageTitle title="Books" />
            <Wrapper>
                 {data.allSanityBook.edges.map(bookItem => {
                    return (
                        <BookItem input={bookItem.node} key={bookItem.node._id} />
                    )
                })}
            </Wrapper>
        </Layout>
    )
}

export default BookPage

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`